import React, { useContext, useState, useEffect } from "react";
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import IconText from "../../common/IconText";
import bkstApi from "../../api";
import BakerApproxLocation from "../../common/gmaps/BakerApproxLocation";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import { Skeleton } from "@mui/material";
import { BAKER_PIC_FOLDER } from "../../common/constants";
import BakerFlavorsPopup from "./BakerFlavorsPopup";
import QuoteFooterAction from "./QuoteFooterAction";
import QuoteFooterInfo from "./QuoteFooterInfo";
import BakerReviewButton from "./BakerReviewButton";
import StarIcon from "@mui/icons-material/Star";
import BakerPhotosButton from "./BakerPhotosButton";
import QuoteDescriptionV2 from "./QuoteDescriptionV2";
import CustomerChat from "./action/CustomerChat";
import { UserContext } from "../../context/UserContext";

export default function QuoteItemV5(props) {
  const { user } = useContext(UserContext);

  const [baker, setBaker] = useState();
  const [photos, setPhotos] = useState();
  const [reviews, setReviews] = useState([]);

  const { request, quote, addEvent } = props;
  const status = props.quote.status;

  useEffect(() => {
    bkstApi(`/bakers/${props.quote.id}/profile`).then((res) => {
      setBaker(res.data.baker);
      setPhotos(res.data.photos);
      setReviews(res.data.reviews);
    });
  }, []);

  return (
    <Paper variant="outlined">
      <Box px={2} py={2}>
        <Box>
          <Grid container spacing={2}>
            <Grid item>
              {baker ? (
                <Avatar alt="" src={`${BAKER_PIC_FOLDER}/${baker.id}/photo400.webp`} style={{ width: "75px", height: "75px" }} />
              ) : (
                <Skeleton variant="circular" width={75} height={75} />
              )}
            </Grid>
            <Grid item>
              {baker ? (
                <Box>
                  <Typography variant="body1">{baker ? `Baker ${baker.name}` : <Skeleton />}</Typography>
                  <Box>
                    <Box style={{ color: "#777", marginTop: "3px" }}>
                      {Boolean(Number(baker.orders)) ? (
                        <Grid container>
                          <Grid item>
                            <StarIcon style={{ fontSize: "1.2rem", color: "#78374a", marginRight: ".4rem" }} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" display="inline" style={{ color: "#000" }}>
                              {Number(baker.rating) > 0 ? `${Number(baker.rating).toFixed(1)}` : "New here!"}&nbsp;
                            </Typography>
                            <Typography variant="body2" display="inline">
                              {`(${baker.orders} orders)`}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <IconText variant="body2" icon={SentimentSatisfiedOutlinedIcon} text="New here!" />
                      )}
                      <BakerApproxLocation location={baker.location} distance={quote.baker.distance} />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box py={1} mx={1} style={{ display: "flex", justifyContent: "space-evenly", borderTop: "1px solid #EEE", borderBottom: "1px solid #EEE" }}>
        <BakerPhotosButton bakerId={quote.id} name={baker && `Baker ${baker.name}`} addEvent={addEvent} src={request.src} />
        <BakerFlavorsPopup bakerId={quote.id} color="primary" variant="text" name={baker && `Baker ${baker.name}`} addEvent={addEvent} />
        <BakerReviewButton bakerId={quote.id} reviews={reviews} name={baker && `Baker ${baker.name}`} addEvent={addEvent} />
        <CustomerChat
          reqId={request.id}
          quoteId={quote.id}
          name={baker && `Baker ${baker.name}`}
          chat={quote.chat}
          showBookButton={status === "active"}
          color="primary"
          variant="text"
          showTooltip={status === "active" && !quote.chat}
        />
      </Box>
      <Box mx={2} my={3}>
        <QuoteDescriptionV2 status={status} quote={quote} request={request} addEvent={addEvent} />
      </Box>
      {(status === "active" || status === "expired" || status === "rbook" || status === "paid") && (
        <Box align="center" my={2}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6}>
              <QuoteFooterInfo status={status} expiration={quote.expiration} quote={quote} />
            </Grid>
            <Grid item sm={6} xs={6}>
              <QuoteFooterAction
                reqId={request.id}
                status={status}
                expiration={quote.expiration}
                quote={quote}
                addEvent={addEvent}
                paymentDueFactor={props.showDepositOption ? 0.25 : 1}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
}
