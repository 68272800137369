import React from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { capitalize } from "../../common/util";
import Moment from "react-moment";

export default function OrderFulfillment(props) {
  return (
    <Paper elevation={0}>
      <Box px={2} py={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{props.order.address}</Typography>
            <Link
              component="a"
              underline="always"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(props.order.address)}`}
            >
              Get directions
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} date & time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <Moment format="ddd, MMM DD, YYYY">{props.order.date}</Moment> [{props.order.time}]
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{props.order.instructions}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
