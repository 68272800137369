import React, { useState } from "react";
import CustomerQuotes from "./request/CustomerQuotes";
import GetQuote from "./wizard/GetQuote";
import Faq from "./support/Faq";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import Footer from "./common/Footer";
import Header from "./Header";
import Order from "./order";
import Home from "./home";
import Collection from "./collection";
import DynamicPage from "./common/DynamicPage";
import OrderReview from "./review/screen/OrderReview";
import OrderFulfillmentEntry from "./order/screen/OrderFulfillmentEntry";
import NoGoFeedback from "./review/screen/NoGoFeedback";
import JoinAsBaker from "./baker/screen/JoinAsBaker";
import Reroute from "./common/Redirect";
import MktGetQuote from "./request/screens/MktGetQuote";
import PostCheckout from "./order/screen/PostCheckout";
import OrderInvoice from "./order/screen/OrderInvoice";
import RefundPolicyPage from "./common/screen/RefundPolicyPage";
import PaymentStatusPage from "./order/screen/PaymentStatusPage";
import CheckoutV2 from "./order/screen/CheckoutV2";
import ResponsiveDrawer from "./common/component/ResponsiveDrawer";
import { THEME } from "./common/constants";
import OrderBalancePay from "./order/screen/OrderBalancePay";
import RecentReviews from "./home/screen/RecentReviews";
import ContextProvider from "./context/UserContext";
import BookDemo from "./baker/components/BookDemo";
import SaaSHomePage from "./baker/screen/SaaSHomePage";

const theme = createTheme(THEME);

export default function App(props) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ContextProvider>
        <div style={{ display: "flex" }}>
          <ResponsiveDrawer open={mobileDrawerOpen} toggle={() => setMobileDrawerOpen(!mobileDrawerOpen)} />
          <main style={{ flexGrow: "1" }}>
            <Header toggleMobileDrawer={() => setMobileDrawerOpen(!mobileDrawerOpen)} />
            <Routes>
              <Route path="/order/:orderId/fulfillment/edit" element={<OrderFulfillmentEntry />} />
              <Route path="/requests/:reqId/quotes/:quoteId/checkout" element={<CheckoutV2 />} />
              <Route path="/request/:reqId/feedback" element={<NoGoFeedback />} />
              <Route path="/requests/:reqId" element={<CustomerQuotes />} />
              <Route path="/quote/:reqId" element={<CustomerQuotes />} />
              <Route path="/payment-status" element={<PaymentStatusPage />} />
              <Route path="/post-checkout/:orderId" element={<PostCheckout />} />
              <Route path="/order/:orderId/review" element={<OrderReview />} />
              <Route path="/order/:orderId/in/:invoiceId" element={<OrderInvoice />} />
              <Route path="/order/:orderId/balance-payment" element={<OrderBalancePay />} />
              <Route path="/order/:orderId" element={<Order />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/join/baker" element={<JoinAsBaker />} />
              <Route path="/book-demo" element={<BookDemo />} />
              <Route path="/get-quote/*" element={<MktGetQuote {...props} />} />
              <Route path="/collection/:handle" element={<Collection />} />
              <Route path="/privacy-policy" element={<DynamicPage {...props} handle="privacy-policy" />} />
              <Route path="/terms-of-service" element={<DynamicPage {...props} handle="terms-of-service" />} />
              <Route path="/refund-policy" element={<RefundPolicyPage />} />
              <Route path="/to/:cat/:id" element={<Reroute />} />
              <Route path="/shop/:handle/get-quote" element={<Navigate replace to="/" />} />
              <Route path="/shop/:handle/*" element={<Navigate replace to="/" />} />
              <Route path="/reviews" element={<RecentReviews />} />
              <Route path="/marketplace" element={<Home />} />
              <Route path="/" element={<SaaSHomePage />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <Footer />
          </main>
        </div>
      </ContextProvider>
    </ThemeProvider>
  );
}
