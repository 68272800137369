import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { TIME_WINDOWS } from "../../common/constants";
import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";

export default function OrderFulfillmentEntry(props) {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [order, setOrder] = useState("");

  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [instructions, setInstructions] = useState("");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/order/${orderId}`)
      .then((res) => {
        setOrder(res.data);

        const orderFulfillment = res.data.fulfillment;
        setType(orderFulfillment.type);
        setAddress(orderFulfillment.address);
        setDate(orderFulfillment.date);
        setTime(orderFulfillment.time || "");
        setInstructions(orderFulfillment.instructions || "");
        setStatus(res.data.status);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [orderId]);

  const onTypeChange = (e) => {
    setType(e.target.value);
    setAddress("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fulfillment = {
      type,
      address,
      date,
      time,
      instructions
    };

    bkstApi.put(`/order/${orderId}/fulfillment`, { fulfillment, consent }).then((res) => {
      navigate(-1);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box pt={3}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">FULFILLMENT</Typography>
            </Grid>
          </Grid>
          <Paper>
            <Box px={3} py={3}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <RadioGroup row name="type" value={type} onChange={onTypeChange}>
                      <FormControlLabel value="pickup" control={<Radio />} label={`Pickup`} size="large" color="primary" />
                      <FormControlLabel value="delivery" control={<Radio />} label={`Delivery`} />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="address"
                      value={address}
                      label="Address"
                      name="address"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={date}
                        variant="outlined"
                        fullWidth
                        id="date"
                        label="Date"
                        name="date"
                        onChange={(e) => setDate(e.target.value)}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="time-label">Time</InputLabel>
                      <Select value={time} labelId="time-label" label="Time" id="time" name="time" onChange={(e) => setTime(e.target.value)}>
                        {TIME_WINDOWS.map((i) => (
                          <MenuItem key={i} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="instructions"
                      value={instructions}
                      label="Instructions"
                      name="instructions"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setInstructions(e.target.value)}
                    />
                  </Grid>
                  {order.paymentType !== "offline" && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        className="consent"
                        control={
                          <Checkbox
                            required={status === "confirmed"}
                            checked={consent}
                            onChange={(e) => setConsent(e.target.checked)}
                            name="consent"
                            color="secondary"
                          />
                        }
                        label="I've discussed these changes with the customer and we're in agreement"
                      />
                      {consent && <Alert severity="info">Customer will be notified when you save these changes</Alert>}
                    </Grid>
                  )}
                  <Grid item xs={12} align="right">
                    <Button variant="text" onClick={(e) => navigate(-1)} style={{ marginRight: "1rem" }}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
