import React from "react";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import Images from "../../request/components/Images";

export default function OrderDetails(props) {
  const details = props.order;

  return (
    <Paper elevation={0}>
      <Box px={2} py={4}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={3}>
            <Images
              pics={details.pics}
              allowDownload={props.allowDownload}
            />
          </Grid>
          <Grid item sm={8} xs={9}>
            <Typography variant="h5" gutterBottom>
              {details.title}
            </Typography>
            <Box mt={1}>
              <Grid container style={{ lineHeight: "2" }}>
                {details.size && (
                  <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary">
                      SIZE:{" "}
                    </Typography>
                    <Typography variant="body2" display="inline">
                      {`${details.shape || ""} ${details.size}`}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary">
                    SERVINGS:{" "}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {details.servings}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary">
                    FROSTING:{" "}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {details.frosting}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary">
                    FLAVOR:{" "}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {details.flavor || `Not specified`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary">
                    FILLING:{" "}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {details.filling || `Not specified`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary">
                    INSCRIPTION:{" "}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {details.inscription || `Not specified`}
                  </Typography>
                </Grid>
              </Grid>
              {details.tags && details.tags.length > 0 && (
                <Box my={1}>
                  {details.tags.map((e, idx) => (
                    <Chip key={idx} size="small" label={e} />
                  ))}
                </Box>
              )}
              {details.decor && details.decor.length > 2 && (
                <Box>
                  <Typography color="textSecondary" variant="caption">
                    <b>Notes</b>: {details.decor}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
